import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Markdown from "../Markdown.js";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import i18next from "../../i18n.js";
import NewsSlider from "../newsSlider";
import moment from "moment";

import LinkList from "../linkList";
import SeoArticle from "../seoArticle";
import SidebarLayout from "../sidebarLayout";

import * as Styles from "../../styles/blogLayout.module.scss";

const BlogPost = ({ pageContext }) => {
  const data = pageContext.node;
  var { t } = i18next;
  var translationContext = {
    locale: pageContext.locale,
    defaultMenu: false,
    translations: data.frontmatter.translations,
  };

  return (
    <div>
      <SeoArticle
        title={data.frontmatter.title}
        description={data.excerpt}
        imagePath={
          data.frontmatter.hero_image
            ? data.frontmatter.hero_image.childImageSharp.gatsbyImageData.images
                .fallback.src
            : ""
        }
        articleData={data}
        locale={pageContext.locale}
        pageContext={pageContext}
      />
      <SidebarLayout
        title={data.frontmatter.title}
        pageContext={pageContext}
        translationContext={translationContext}
        main={
          <div class="blogpost main-padding">
            <h1>{data.frontmatter.title}</h1>
            <p className={Styles.postAttributeLine}>
              Veröffentlicht am {data.frontmatter.date}
              {data.frontmatter.author ? (
                <span>
                  {" "}
                  von <Markdown>{data.frontmatter.author}</Markdown>
                </span>
              ) : (
                ""
              )}
              {" | "}
              {data.categoryNode
                .filter((a) => a !== null)
                .map((category) => (
                  <a href={category.data.Slug.toLowerCase()}>
                    {category.data.Name } 
                    {" | "}
                  </a>
                ))}
            </p>
            <MDXRenderer>{data.body}</MDXRenderer>
            <div class="hideOnPrint"></div>
          </div>
        }
        sidebar={
          <div>
            <div className={Styles.heroImageBox}>
              <div 
                className={Styles.heroImage}>
              <GatsbyImage
                image={getImage(data.frontmatter.hero_image)}
                imgStyle={{ objectFit: "fill" }}
              /></div>
              <div className={Styles.eventDate}>{data.frontmatter.eventDate?new moment(data.frontmatter.eventDate).format('DD.MM.YYYY'):''}</div>
            </div>
            {data.frontmatter.links ? (<LinkList
              links={data.frontmatter.links}
              title={t("links")}
              locale={pageContext.locale}
            />) : ''}
            <div className={Styles.newsTile}>
              <NewsSlider size="small"></NewsSlider>
            </div>
          </div>
        }
      ></SidebarLayout>
    </div>
  );
};

/*export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`*/
export default BlogPost;
